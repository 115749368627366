@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEF1F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  font-family: 'Roboto';
}

/* Scrollbar-Styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* End of Scrollbar-Styles */

/* Input-Number-Field */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* End of Input-Number-Field */

/* RSuite-Classes */

.rs-picker-toggle.rs-btn {
  background-color: #eef1f6 !important;
  border-radius: 3px;
}

.rs-picker-toggle:hover {
  border: 1px solid transparent !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #575757;
  font-weight: 600 !important;
}
.rs-btn-xs {
  color: #575757;
  font-weight: 400 !important;
}

.rs-picker-toggle-caret {
  color: #3c8dde;
}

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  border-radius: 3px;
}

.rs-dropdown-menu{
  max-height: 250px !important;
  overflow: auto !important;
}

.rs-uploader-trigger {
  height: 100%;
}

.rs-uploader-trigger input[type="file"] {
  width: 100px;
}

.rs-auto-complete .rs-input{
  background-color: #eef1f6 !important;
  border-radius: 2px;
}
.rs-input:focus{
  border: 1px solid transparent !important;
}
.rs-input:hover{
  border: 1px solid transparent !important;
}


.rs-input-group .rs-input-group-inside .rs-input-group-focus:hover{
  border: 1px solid transparent !important;
}

::-webkit-input-placeholder {
  color : #000 !important;
}

::placeholder {
  color : #000 !important;
}
/* End of Rsuite-Classes */


.pac-container{
  z-index: 1000000 !important;
  /* display: block !important; */
}

.pac-container::after{
  z-index: 1000000 !important;
  /* display: block !important; */
}



::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9397AA !important;
  font-weight: 400 !important;
  font-family: 'Roboto' !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #9397AA !important;
  font-weight: 400 !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #9397AA !important;
  font-weight: 400 !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #9397AA !important;
  font-weight: 400 !important;
}


/* Material UI Tab styles override */

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
::-webkit-input-placeholder {
  color: #9397AA;
  opacity: 0.5; /* Firefox */
}

::placeholder {
  color: #9397AA;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #AFAFAF;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #AFAFAF;
}

.margin-provider {
  margin: 8px;
}
.rs-btn-subtle {
  background-color: #EEF1F6;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  color: #414243;
}

.rs-modal-title {
  font-family: 'Roboto';
  font-size: 21px !important;
  color: #414243;
  font-weight: 500;
  letter-spacing: 0.1;
}

.rs-dropdown-item-content {
  font-family: 'Roboto';
  font-size: 14px;
  color: #414243;
  font-weight: 500;
}

.rs-modal-header .rs-modal-header-close {
  font-size: 16px;
  color: #414243;
  font-weight: 600;
}

.rs-uploader-text .rs-uploader-file-items {
  margin-top: 0px;
}
