.rs-btn-subtle {
  background-color: #EEF1F6;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  color: #414243;
}

.rs-modal-title {
  font-family: 'Roboto';
  font-size: 21px !important;
  color: #414243;
  font-weight: 500;
  letter-spacing: 0.1;
}

.rs-dropdown-item-content {
  font-family: 'Roboto';
  font-size: 14px;
  color: #414243;
  font-weight: 500;
}

.rs-modal-header .rs-modal-header-close {
  font-size: 16px;
  color: #414243;
  font-weight: 600;
}

.rs-uploader-text .rs-uploader-file-items {
  margin-top: 0px;
}