::placeholder {
  color: #9397AA;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #AFAFAF;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #AFAFAF;
}

.margin-provider {
  margin: 8px;
}